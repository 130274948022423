// https://github.com/gatsbyjs/gatsby/issues/21120

exports.onRouteUpdate = ({ location }) =>
  document.readyState === 'completed'
    ? scrollToAnchor(location)
    : setTimeout(() => scrollToAnchor(location), 2000)

function scrollToAnchor(location) {
  // Check for location so build does not fail
  if (location && location.hash) {
    const e = document.querySelector(`${location.hash}`)
    window.scrollTo({
      top: e.offsetTop + 200, // 200 for the navbar
      behavior: 'smooth',
    })
  }

  return true
}
