exports.components = {
  "component---src-components-author-page-jsx": () => import("./../../../src/components/AuthorPage.jsx" /* webpackChunkName: "component---src-components-author-page-jsx" */),
  "component---src-components-category-page-jsx": () => import("./../../../src/components/CategoryPage.jsx" /* webpackChunkName: "component---src-components-category-page-jsx" */),
  "component---src-components-redirect-page-jsx": () => import("./../../../src/components/RedirectPage.jsx" /* webpackChunkName: "component---src-components-redirect-page-jsx" */),
  "component---src-components-tag-page-jsx": () => import("./../../../src/components/TagPage.jsx" /* webpackChunkName: "component---src-components-tag-page-jsx" */),
  "component---src-pages-2018-12-09-how-tell-if-your-had-a-productive-day-index-mdx": () => import("./../../../src/pages/2018/12/09/how-tell-if-your-had-a-productive-day/index.mdx" /* webpackChunkName: "component---src-pages-2018-12-09-how-tell-if-your-had-a-productive-day-index-mdx" */),
  "component---src-pages-2018-12-09-how-to-write-tasks-index-mdx": () => import("./../../../src/pages/2018/12/09/how-to-write-tasks/index.mdx" /* webpackChunkName: "component---src-pages-2018-12-09-how-to-write-tasks-index-mdx" */),
  "component---src-pages-2018-12-09-the-real-reason-why-startups-fail-index-mdx": () => import("./../../../src/pages/2018/12/09/the-real-reason-why-startups-fail/index.mdx" /* webpackChunkName: "component---src-pages-2018-12-09-the-real-reason-why-startups-fail-index-mdx" */),
  "component---src-pages-2018-12-15-new-year-resolution-are-really-confessions-index-mdx": () => import("./../../../src/pages/2018/12/15/new-year-resolution-are-really-confessions/index.mdx" /* webpackChunkName: "component---src-pages-2018-12-15-new-year-resolution-are-really-confessions-index-mdx" */),
  "component---src-pages-2018-12-18-the-sane-way-to-reach-goals-index-mdx": () => import("./../../../src/pages/2018/12/18/the-sane-way-to-reach-goals/index.mdx" /* webpackChunkName: "component---src-pages-2018-12-18-the-sane-way-to-reach-goals-index-mdx" */),
  "component---src-pages-2018-12-24-what-should-and-shouldnt-be-in-your-mvp-index-mdx": () => import("./../../../src/pages/2018/12/24/what-should-and-shouldnt-be-in-your-mvp/index.mdx" /* webpackChunkName: "component---src-pages-2018-12-24-what-should-and-shouldnt-be-in-your-mvp-index-mdx" */),
  "component---src-pages-2019-01-18-multitasking-is-just-lack-of-focus-index-mdx": () => import("./../../../src/pages/2019/01/18/multitasking-is-just-lack-of-focus/index.mdx" /* webpackChunkName: "component---src-pages-2019-01-18-multitasking-is-just-lack-of-focus-index-mdx" */),
  "component---src-pages-2019-01-31-the-new-year-resolutions-you-will-accomplish-index-mdx": () => import("./../../../src/pages/2019/01/31/the-new-year-resolutions-you-will-accomplish/index.mdx" /* webpackChunkName: "component---src-pages-2019-01-31-the-new-year-resolutions-you-will-accomplish-index-mdx" */),
  "component---src-pages-2019-03-04-what-is-scrum-in-agile-and-is-scrum-good-for-startups-index-mdx": () => import("./../../../src/pages/2019/03/04/what-is-scrum-in-agile-and-is-scrum-good-for-startups/index.mdx" /* webpackChunkName: "component---src-pages-2019-03-04-what-is-scrum-in-agile-and-is-scrum-good-for-startups-index-mdx" */),
  "component---src-pages-2020-04-14-limitations-constraints-safeguards-index-mdx": () => import("./../../../src/pages/2020/04/14/limitations-constraints-safeguards/index.mdx" /* webpackChunkName: "component---src-pages-2020-04-14-limitations-constraints-safeguards-index-mdx" */),
  "component---src-pages-2020-04-25-what-makes-todo-space-different-index-mdx": () => import("./../../../src/pages/2020/04/25/what-makes-todo-space-different/index.mdx" /* webpackChunkName: "component---src-pages-2020-04-25-what-makes-todo-space-different-index-mdx" */),
  "component---src-pages-2020-06-15-humble-concerns-about-epic-issues-index-mdx": () => import("./../../../src/pages/2020/06/15/humble-concerns-about-epic-issues/index.mdx" /* webpackChunkName: "component---src-pages-2020-06-15-humble-concerns-about-epic-issues-index-mdx" */),
  "component---src-pages-2020-08-02-an-obvious-project-management-mistake-that-is-often-overlooked-index-mdx": () => import("./../../../src/pages/2020/08/02/an-obvious-project-management-mistake-that-is-often-overlooked/index.mdx" /* webpackChunkName: "component---src-pages-2020-08-02-an-obvious-project-management-mistake-that-is-often-overlooked-index-mdx" */),
  "component---src-pages-2022-10-04-your-todo-list-is-a-choice-index-mdx": () => import("./../../../src/pages/2022/10/04/your-todo-list-is-a-choice/index.mdx" /* webpackChunkName: "component---src-pages-2022-10-04-your-todo-list-is-a-choice-index-mdx" */),
  "component---src-pages-2022-10-13-not-knowing-cogs-is-killing-your-gains-index-mdx": () => import("./../../../src/pages/2022/10/13/not-knowing-cogs-is-killing-your-gains/index.mdx" /* webpackChunkName: "component---src-pages-2022-10-13-not-knowing-cogs-is-killing-your-gains-index-mdx" */),
  "component---src-pages-2022-10-21-never-skip-project-decomposition-index-mdx": () => import("./../../../src/pages/2022/10/21/never-skip-project-decomposition/index.mdx" /* webpackChunkName: "component---src-pages-2022-10-21-never-skip-project-decomposition-index-mdx" */),
  "component---src-pages-2022-10-28-i-hate-timesheets-index-mdx": () => import("./../../../src/pages/2022/10/28/i-hate-timesheets/index.mdx" /* webpackChunkName: "component---src-pages-2022-10-28-i-hate-timesheets-index-mdx" */),
  "component---src-pages-2022-11-28-do-not-use-painkiller-vs-vitamin-in-product-management-index-mdx": () => import("./../../../src/pages/2022/11/28/do-not-use-painkiller-vs-vitamin-in-product-management/index.mdx" /* webpackChunkName: "component---src-pages-2022-11-28-do-not-use-painkiller-vs-vitamin-in-product-management-index-mdx" */),
  "component---src-pages-2023-01-27-overwhelmed-with-many-projects-here-is-how-to-fix-it-index-mdx": () => import("./../../../src/pages/2023/01/27/overwhelmed-with-many-projects-here-is-how-to-fix-it/index.mdx" /* webpackChunkName: "component---src-pages-2023-01-27-overwhelmed-with-many-projects-here-is-how-to-fix-it-index-mdx" */),
  "component---src-pages-2024-01-07-enforcing-compliance-when-managing-software-projects-index-mdx": () => import("./../../../src/pages/2024/01/07/enforcing-compliance-when-managing-software-projects/index.mdx" /* webpackChunkName: "component---src-pages-2024-01-07-enforcing-compliance-when-managing-software-projects-index-mdx" */),
  "component---src-pages-2024-02-12-5-whys-technique-avoiding-common-pitfalls-index-mdx": () => import("./../../../src/pages/2024/02/12/5-whys-technique-avoiding-common-pitfalls/index.mdx" /* webpackChunkName: "component---src-pages-2024-02-12-5-whys-technique-avoiding-common-pitfalls-index-mdx" */),
  "component---src-pages-2024-03-16-how-to-prioritize-work-for-mvp-index-mdx": () => import("./../../../src/pages/2024/03/16/how-to-prioritize-work-for-mvp/index.mdx" /* webpackChunkName: "component---src-pages-2024-03-16-how-to-prioritize-work-for-mvp-index-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-authors-jsx": () => import("./../../../src/pages/authors.jsx" /* webpackChunkName: "component---src-pages-authors-jsx" */),
  "component---src-pages-categories-jsx": () => import("./../../../src/pages/categories.jsx" /* webpackChunkName: "component---src-pages-categories-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-onboarding-how-to-start-with-todo-space-index-mdx": () => import("./../../../src/pages/onboarding/how-to-start-with-todo-space/index.mdx" /* webpackChunkName: "component---src-pages-onboarding-how-to-start-with-todo-space-index-mdx" */),
  "component---src-pages-onboarding-which-plan-do-i-need-index-mdx": () => import("./../../../src/pages/onboarding/which-plan-do-i-need/index.mdx" /* webpackChunkName: "component---src-pages-onboarding-which-plan-do-i-need-index-mdx" */),
  "component---src-pages-tags-jsx": () => import("./../../../src/pages/tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */)
}

